exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-about-tsx": () => import("./../../../src/pages/Home/About.tsx" /* webpackChunkName: "component---src-pages-home-about-tsx" */),
  "component---src-pages-home-facts-tsx": () => import("./../../../src/pages/Home/Facts.tsx" /* webpackChunkName: "component---src-pages-home-facts-tsx" */),
  "component---src-pages-home-jumbo-tsx": () => import("./../../../src/pages/Home/Jumbo.tsx" /* webpackChunkName: "component---src-pages-home-jumbo-tsx" */),
  "component---src-pages-home-project-tsx": () => import("./../../../src/pages/Home/Project.tsx" /* webpackChunkName: "component---src-pages-home-project-tsx" */),
  "component---src-pages-home-projects-tsx": () => import("./../../../src/pages/Home/Projects.tsx" /* webpackChunkName: "component---src-pages-home-projects-tsx" */),
  "component---src-pages-home-testimonials-tsx": () => import("./../../../src/pages/Home/Testimonials.tsx" /* webpackChunkName: "component---src-pages-home-testimonials-tsx" */),
  "component---src-pages-home-word-roll-tsx": () => import("./../../../src/pages/Home/WordRoll.tsx" /* webpackChunkName: "component---src-pages-home-word-roll-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

